angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.send', {
            url: '/send?type&template&document',
            views: {
                "main": {
                    controller: 'SendCtrl',
                    templateUrl: 'web/main/send/send.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                documentoParam: function ($stateParams, signatureService) {
                    if ($stateParams.document) {
                        return signatureService.retrieveFirma($stateParams.document);
                    } else {
                        return null;
                    }
                },
                templateParam: function ($stateParams, templateService) {
                    if ($stateParams.template) {
                        return templateService.getPlantilla($stateParams.template);
                    } else {
                        return null;
                    }
                }
            },
            data: {
                pageTitle: 'New Signature'
            }
        });
    }).controller('SendCtrl', function ($rootScope, $scope, $stateParams, session, documentoParam, templateParam, signatureService, $state, templateService, $window, $translate, fingerinkSession, swalService) {

        function myConfirmation() {
            return $translate.instant('¿Estás seguro que quieres salir del proceso de firma? Se pueden perder datos');
        }

        $scope.$on('$destroy', function () {
            window.onbeforeunload = null;
        });



        if (!(typeof module !== 'undefined' && module.exports) ) {
            window.onbeforeunload = myConfirmation;
        }
        var that = this;

        if (!session.get().user.emailConfirmed) {
            swal($translate.instant('Confirma tu correo'), $translate.instant('Este correo no está confirmado, por favor, revisa tu bandeja de entrada y confirma tu dirección de correo'), 'info');
            $state.go('main.dashboard');
        }


        function checkSessionPlantillas(then) {
            then();
        }

        that.firma = {
            type: $stateParams.type,
            documentFields: {
                name: $translate.instant('Yo'),
                fields: [],
                signType: 'DOCUMENT'
            },
            oneDocumentSignerFields: {
                name: 'Editar documento',
                fields: [],
                signType: 'ODS'
            },
            fields: []
        };

        that.stepActual = 0;
        that.step = 'insertfirmantes';
        that.steps = {
            Advanced: ['insertfirmantes', 'prepareDocument'],
            Fast: ['insertfirmantes'],
            Email: ['insertfirmantes'],
            SelfSigning: ['insertfirmantes', 'prepareDocument'],
            InPerson: ['insertfirmantes', 'prepareDocument'/*, 'rellenarDocument'*/]
        };


        if (templateParam) {
            /*
                        if (that.state.totalPlantillas < that.state.totalPlantillasGastadas) {
                            swal($translate.instant('Tienes más plantillas de las contratadas'), $translate.instant('Para usar las plantillas, borre plantillas o contrata un plan superior'));
                            $state.go('main.templates');
                        }
            */


            angular.copy(templateParam.data, that.firma);
            that.firma.template = templateParam.data;
            that.firma.id = null;

            that.steps = {
                Advanced: ['insertfirmantes'],
                Fast: ['insertfirmantes'],
                Email: ['insertfirmantes'],
                SelfSigning: ['insertfirmantes'],
                InPerson: ['insertfirmantes', 'rellenarDocument']
            };

            that.firma.fields = [];
            that.firma.documentFields = that.firma.documentFields || { fields: [] };
            that.firma.oneDocumentSignerFields = that.firma.oneDocumentSignerFields || { fields: [] };
            that.firma.signers = that.firma.signers || [];
            [that.firma.documentFields, that.firma.oneDocumentSignerFields, ...that.firma.signers].forEach(
                s => {
                    if (s && s.fields) {
                        s.fields.forEach(f => {
                            f.signer = s;
                            that.firma.fields.push(f);
                        });
                    }
                });
        }

        if (documentoParam) {
            that.firma.subject = documentoParam.data.subject;
            that.firma.text = documentoParam.data.text;
            that.firma.pages = documentoParam.data.pages;
            that.firma.id = documentoParam.data.id;
        }


        that.siguiente = function () {

            $window.scrollTo(0, 0);

            if (that.stepActual < that.steps[that.firma.type].length - 1) {
                that.stepActual++;
                that.step = that.steps[that.firma.type][that.stepActual];
            } else {
                that.enviar();
            }
        };

        that.anterior = function () {
            if (that.stepActual > 0) {
                that.stepActual--;
                that.step = that.steps[that.firma.type][that.stepActual];
            }
        };




        that.enviar = function () {
            var signType = {
                Advanced: $translate.instant("¿Enviar a firmar este documento?"),
                Fast: $translate.instant("¿Enviar a firmar este documento?"),
                Email: $translate.instant("¿Enviar a firmar este documento?"),
                SelfSigning: $translate.instant("¿Terminar el proceso de autofirma?"),
                InPerson: $translate.instant("¿Finalizar la firma presencial?")
            };

            let fields = that.firma.fields;
            swalService.requestSwal(signType[that.firma.type], null, 'warning', () => {
                fields.forEach(f => {
                    f.signer.fields = f.signer.fields || [];
                    f.signer.fields.push(f);
                    delete f.signer;
                });
                delete that.firma.fields;
                return signatureService.createFirma(that.firma);
            }).then(response => {
                fingerinkSession.renoveToken();
                that.firma.fields = fields;
                that.firma.signers = that.firma.signers || [];
                [that.firma.documentFields, that.firma.oneDocumentSignerFields, ...that.firma.signers].forEach(s => { if (s && s.fields) { s.fields.forEach(f => f.signer = s); } });

                // if (that.firma.template && that.firma.template.id) {
                swal($translate.instant("¡Correcto!"), $translate.instant("Se ha enviado la firma correctamente"), "success");
                $state.go('main.documents', { type: '' });
                /*} else {
                    swal({
                        title: $translate.instant('¡Correcto!'),
                        text: $translate.instant('Se ha enviado la firma correctamente, ¿Deseas guardar el documento como plantilla?'),
                        type: 'success',
                        showCancelButton: true,
                        closeOnConfirm: false,
                        confirmButtonText: $translate.instant('Guardar como plantilla'),
                        cancelButtonText: $translate.instant("Ir a documentos"),
                        showLoaderOnConfirm: true
                    },
                        function (inputValue) {
                            if (inputValue) {
                                checkSessionPlantillas(function () {
                                    templateService.createPlantillaFromFirma(response.data.id).then(function () {
                                        swal($translate.instant("Guardado"), $translate.instant("Se ha guardado la plantilla correctamente"), "success");
                                        $state.go('main.documents', { type: '' });
                                    }, function () {
                                        swal($translate.instant("Algo ha ido mal"), $translate.instant("Ha habido un problema guardando el documento como plantilla"), "error");
                                        $state.go('main.documents', { type: '' });
                                    });
                                });
                            } else {
                                $state.go('main.documents', { type: '' });
                            }
                        });
                }*/
                window.onbeforeunload = null;

                if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                    $window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'Signatures',
                        eventAction: 'send',
                        eventLabel: that.firma.type
                    });
                }

            },
                () => {
                    swal($translate.instant("Algo ha ido mal"), $translate.instant("Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante"), "error");

                    that.firma.fields = fields;
                    that.firma.signers = that.firma.signers || [];
                    [that.firma.documentFields, that.firma.oneDocumentSignerFields, ...that.firma.signers].forEach(s => { if (s && s.fields) { s.fields.forEach(f => f.signer = s); } });
                }
            );

        };

    });
